import { defineNuxtPlugin } from 'nuxt/app'
import { consola } from 'consola'
import { configRouters } from '../app/config/routers'

let globalSettings: any = {}
let globalSettingsExpire = 0
let time = 15
const isDev = process.env.NODE_ENV !== 'production' ?? true

const refreshData = async (cache: number = 1) => {
    return $fetch('/cms/initial/data-global', {
        method: 'POST',
        params: {
            cache: cache
        }
    })
        .then((data: any) => {
            console.log('🚀 ~ .then ~ data:', data)
            return data
        })
        .catch((error) => {
            consola.error('Error: refresh data global !')
            consola.error(error)
        })
}

export default defineNuxtPlugin(async (nuxtApp) => {
    // const fetchDataLive: any = inject("fetch_data_live");
    // const fetchDataMenu: any = inject("fetch_data_menu");
    // consola.warn('Time stamp: ' + timestamp)
    // consola.warn('Time global: ' + globalSettingsExpire)
    let timestamp = new Date().getTime()

    // // consola.warn({
    // //   isClient: nuxtApp.isHydrating
    // // })

    if (globalSettingsExpire <= timestamp) {
        let { data } = await useAsyncData('refreshData', async () =>
            Promise.all([
                refreshData(1)
                // fetchDataLive(),
                // fetchDataMenu()
            ])
        )
        //@ts-ignore
        globalSettings = data.value[0]

        globalSettings.routes = [...(globalSettings.routes || []), ...configRouters]
        globalSettings['isDev'] = isDev
        globalSettingsExpire = timestamp + time * 60 * 1000
    }

    nuxtApp.vueApp.provide('globalSettings', globalSettings)

    // await Promise.all([
    //     refreshData(),
    //     fetchDataLive()
    // ]).then((data: any) => {
    //     globalSettings = data[0]
    // })
    // globalSettings.routes = [...globalSettings.routes, ...configRouters]
    // globalSettings['isDev'] = isDev
    // //globalSettingsExpire = timestamp + time * 60 * 1000
    // nuxtApp.vueApp.provide('globalSettings', globalSettings)
    // //nuxtApp.provide('globalSettingsNuxt', globalSettings);
})
