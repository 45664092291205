import revive_payload_client_Ym1ZPednku from "/app/node_modules/.pnpm/nuxt@3.9.0_@types+node@18.19.4_sass@1.69.6_vite@4.5.1/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_3VAfRN553d from "/app/node_modules/.pnpm/nuxt@3.9.0_@types+node@18.19.4_sass@1.69.6_vite@4.5.1/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_G0IT5kaumN from "/app/node_modules/.pnpm/nuxt@3.9.0_@types+node@18.19.4_sass@1.69.6_vite@4.5.1/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_hcAoR0KrBA from "/app/node_modules/.pnpm/nuxt-site-config@2.2.15_vite@4.5.1_vue@3.4.3/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_SKsMITnYYC from "/app/node_modules/.pnpm/nuxt@3.9.0_@types+node@18.19.4_sass@1.69.6_vite@4.5.1/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_bGH2PS09dE from "/app/node_modules/.pnpm/nuxt@3.9.0_@types+node@18.19.4_sass@1.69.6_vite@4.5.1/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_CyVBrMQU3T from "/app/node_modules/.pnpm/@pinia+nuxt@0.4.11_vue@3.4.3/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_ikhEXzPwtA from "/app/node_modules/.pnpm/nuxt@3.9.0_@types+node@18.19.4_sass@1.69.6_vite@4.5.1/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_s0tkSlMITJ from "/app/node_modules/.pnpm/@nuxt+image@1.1.0/node_modules/@nuxt/image/dist/runtime/plugin.mjs";
import plugin_jqQEI1PUPt from "/app/node_modules/.pnpm/@zadigetvoltaire+nuxt-gtm@0.0.13_nuxt@3.9.0_vue@3.4.3/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import plugin_rnYUKUUEQi from "/app/node_modules/.pnpm/nuxt-directus-seo@1.7.9_h3@1.9.0/node_modules/nuxt-directus-seo/dist/runtime/plugin.mjs";
import plugin_j4Th6i5EE8 from "/app/node_modules/.pnpm/nuxt-live-editor@1.5.93_h3@1.9.0_postcss@8.4.32_vite@4.5.1_vue@3.4.3_webpack@5.89.0/node_modules/nuxt-live-editor/dist/runtime/plugin.mjs";
import plugin_wfvwQxXnB1 from "/app/node_modules/.pnpm/nuxt-commons-ui@1.4.26_h3@1.9.0/node_modules/nuxt-commons-ui/dist/runtime/plugin.mjs";
import plugin_ffnEXoIB73 from "/app/node_modules/.pnpm/nuxt-directus@5.6.0/node_modules/nuxt-directus/dist/runtime/plugin.mjs";
import unocss_MzCDxu9LMj from "/app/.nuxt/unocss.mjs";
import plugin_lZVVtEnNKv from "/app/modules/hooks/runtime/plugin.ts";
import chunk_reload_client_aYp4zIOgFj from "/app/node_modules/.pnpm/nuxt@3.9.0_@types+node@18.19.4_sass@1.69.6_vite@4.5.1/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import aosjs_VksiWCg8zg from "/app/plugins/aosjs.ts";
import auto_animate_uTpJuTGsMC from "/app/plugins/auto-animate.ts";
import html_to_text_1wlP4VqA0o from "/app/plugins/html-to-text.ts";
import global_2XP5sjpyNs from "/app/plugins/global.ts";
import recaptcha_85gNSCNFUU from "/app/plugins/recaptcha.ts";
import swiperjs_vRysfokWmD from "/app/plugins/swiperjs.ts";
export default [
  revive_payload_client_Ym1ZPednku,
  unhead_3VAfRN553d,
  router_G0IT5kaumN,
  _0_siteConfig_hcAoR0KrBA,
  payload_client_SKsMITnYYC,
  check_outdated_build_client_bGH2PS09dE,
  plugin_vue3_CyVBrMQU3T,
  components_plugin_KR1HBZs4kY,
  prefetch_client_ikhEXzPwtA,
  plugin_s0tkSlMITJ,
  plugin_jqQEI1PUPt,
  plugin_rnYUKUUEQi,
  plugin_j4Th6i5EE8,
  plugin_wfvwQxXnB1,
  plugin_ffnEXoIB73,
  unocss_MzCDxu9LMj,
  plugin_lZVVtEnNKv,
  chunk_reload_client_aYp4zIOgFj,
  aosjs_VksiWCg8zg,
  auto_animate_uTpJuTGsMC,
  html_to_text_1wlP4VqA0o,
  global_2XP5sjpyNs,
  recaptcha_85gNSCNFUU,
  swiperjs_vRysfokWmD
]