import {defineNuxtPlugin} from "nuxt/app";
import { consola } from 'consola'

export default defineNuxtPlugin(async (nuxtApp: any) => {

    nuxtApp.hook('app:created', async () => {
         consola.warn("app:created")

		//const { $globalSettings } = nuxtApp

		//console.log("Global hooks: ",$globalSettings)

    });

    nuxtApp.hook('page:start', async () => {
        if (process.client) {
            console.log("client")
        }
    })
})
