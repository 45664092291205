export const configRouters = [
    { name: 'collection', file: 'collection', path: '/bo-suu-tap/', alias: ['/en/collection'] },
    {
        name: 'collection_detail',
        file: 'collection/[slug]',
        path: '/bo-suu-tap/:slug',
        alias: ['/en/collection/:slug']
    },
    { name: 'home', file: 'home', path: '/', alias: ['/en/'] },
    { name: 'search', file: 'search', path: '/tim-kiem', alias: ['/en/search'] },
    { name: 'about', file: 'about', path: '/ve-chung-toi', alias: ['/en/about'] },
    { name: 'introduction', file: 'introduction', path: '/gioi-thieu', alias: ['/en/introduction'] },
    { name: 'contact', file: 'contact', path: '/lien-he', alias: ['/en/contact'] },
    // { name: 'agency', file: 'agency', path: '/tim-cua-hang', alias: ['/en/agency'] },

    { name: 'news', file: 'news', path: '/tin-tuc', alias: ['/en/news'] },
    {
        name: 'news_detail',
        file: 'news/[slug]',
        path: '/tin-tuc/:slug',
        alias: ['/en/news/:slug']
    },
    { name: 'product', file: 'product', path: '/san-pham', alias: ['/en/product'] },
    {
        name: 'product_detail',
        file: 'product/[slug]',
        path: '/san-pham/:slug',
        alias: ['/en/product/:slug']
    },
    {
        name: 'category_detail',
        file: 'category/[slug]',
        path: '/loai-san-pham/:slug',
        alias: ['/en/category/:slug']
    },
    {
        name: 'shopping_cart',
        file: 'shoppingCart',
        path: '/lien-he-mua-hang',
        alias: ['/en/cart']
    },
    {
        name: 'place_to_buy',
        file: 'placeToBuy',
        path: '/tim-noi-mua',
        alias: ['/en/placeToBuy']
    },
    {
        name: 'guarantee',
        file: 'guarantee',
        path: '/bao-hanh',
        alias: ['/en/guarantee']
    },
    {
        name: 'installation_support',
        file: 'installationSupport',
        path: '/ho-tro-lap-dat',
        alias: ['/en/installationSupport']
    },
    {
        name: 'inspired',
        file: 'inspired',
        path: '/truyen-cam-hung',
        alias: ['/en/inspired']
    },
    {
        name: 'style_detail',
        file: 'inspired/style/[slug]',
        path: '/phong-cach/:slug',
        alias: ['/en/style/:slug']
    },
    {
        name: 'gallery_index',
        file: 'gallery',
        path: '/thu-vien',
        alias: ['/en/gallery']
    },
    {
        name: 'gallery_image',
        file: 'gallery/image/[slug]',
        path: '/thu-vien/:slug',
        alias: ['/en/gallery/:slug']
    },
    {
        name: 'agency',
        file: 'agency',
        path: '/dai-ly',
        alias: ['/en/agency']
    },
    {
        name: 'agency_detail',
        file: 'agency/[slug]',
        path: '/dai-ly/:slug',
        alias: ['/en/agency/:slug']
    }
]
