import { defineNuxtPlugin } from '#app/nuxt'
import { LazyButtonBackToTop, LazyGlobalBannerEffect, LazyGlobalBoxCardEvent, LazyGlobalBreadcrumb, LazyGlobalFooter, LazyGlobalHeader, LazyGlobalLoadingSearch, LazyGlobalLoading, LazyGlobalMegaMenu, LazyGlobalMenuMobile, LazyGlobalPagination, LazyGlobalPopupSearch, LazyGlobalPopupAddProduct, LazyGlobalPopupCopySuccess, LazyGlobalPopupSignUpAgency, LazyGlobalWrapperContactFooter, LazyIconsCopy, LazyIconsFacebook, LazyIconsInstagram, LazyIconsShowFilter, LazyMenuBackItem, LazyMenuItemHasChild, LazyMenuItemMega } from '#components'
const lazyGlobalComponents = [
  ["ButtonBackToTop", LazyButtonBackToTop],
["GlobalBannerEffect", LazyGlobalBannerEffect],
["GlobalBoxCardEvent", LazyGlobalBoxCardEvent],
["GlobalBreadcrumb", LazyGlobalBreadcrumb],
["GlobalFooter", LazyGlobalFooter],
["GlobalHeader", LazyGlobalHeader],
["GlobalLoadingSearch", LazyGlobalLoadingSearch],
["GlobalLoading", LazyGlobalLoading],
["GlobalMegaMenu", LazyGlobalMegaMenu],
["GlobalMenuMobile", LazyGlobalMenuMobile],
["GlobalPagination", LazyGlobalPagination],
["GlobalPopupSearch", LazyGlobalPopupSearch],
["GlobalPopupAddProduct", LazyGlobalPopupAddProduct],
["GlobalPopupCopySuccess", LazyGlobalPopupCopySuccess],
["GlobalPopupSignUpAgency", LazyGlobalPopupSignUpAgency],
["GlobalWrapperContactFooter", LazyGlobalWrapperContactFooter],
["IconsCopy", LazyIconsCopy],
["IconsFacebook", LazyIconsFacebook],
["IconsInstagram", LazyIconsInstagram],
["IconsShowFilter", LazyIconsShowFilter],
["MenuBackItem", LazyMenuBackItem],
["MenuItemHasChild", LazyMenuItemHasChild],
["MenuItemMega", LazyMenuItemMega],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
