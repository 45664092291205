// @ts-ignore

import { defineNuxtPlugin } from '#app'
import AOS from 'aos'
import 'aos/dist/aos.css'

export default defineNuxtPlugin((nuxtApp: any) => {
    if (typeof window !== 'undefined') {
        nuxtApp.AOS = AOS.init({
            once: false
        })
    }
})
