<template>
    <div class="flex flex-col items-center justify-center h-screen">
        <h1 class="text-7xl text-primary font-bold text-center">404 Page</h1>
        <button
            @click="handleError"
            class="px-5 py-2 rounded bg-white b-2 b-primary hover:bg-primary hover:text-white duration-200 font-semibold mt-5"
        >
            Back to home
        </button>
    </div>
</template>

<script setup lang="ts">
const props = defineProps({
    error: Object
})
const handleError = () => clearError({ redirect: '/' })
</script>
