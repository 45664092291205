import { default as defaultMMV5qJuxEqMeta } from "/app/pages/default.vue?macro=true";
import { default as collection2v3lynU6UOMeta } from "/app/app/views/collection?macro=true";
import { default as _91slug_93fYrUodh70gMeta } from "/app/app/views/collection/[slug]?macro=true";
import { default as homew7y6eWECMEMeta } from "/app/app/views/home?macro=true";
import { default as searcht3XBQZO6UAMeta } from "/app/app/views/search?macro=true";
import { default as aboutkUC1q6gCvzMeta } from "/app/app/views/about?macro=true";
import { default as introductionsyivvGFcn1Meta } from "/app/app/views/introduction?macro=true";
import { default as contact9xppTWObgXMeta } from "/app/app/views/contact?macro=true";
import { default as newsfQSK3wWEBvMeta } from "/app/app/views/news?macro=true";
import { default as _91slug_93PELkVboBtMMeta } from "/app/app/views/news/[slug]?macro=true";
import { default as product1ETEH5FmI1Meta } from "/app/app/views/product?macro=true";
import { default as _91slug_93PT6EbgbpsnMeta } from "/app/app/views/product/[slug]?macro=true";
import { default as _91slug_93zKaOgT6NKAMeta } from "/app/app/views/category/[slug]?macro=true";
import { default as shoppingCarthdS3tBOsodMeta } from "/app/app/views/shoppingCart?macro=true";
import { default as placeToBuyCfuUBr4FlqMeta } from "/app/app/views/placeToBuy?macro=true";
import { default as guaranteew5cBcpD4bIMeta } from "/app/app/views/guarantee?macro=true";
import { default as installationSupportB00PeT6vpDMeta } from "/app/app/views/installationSupport?macro=true";
import { default as inspiredscZ9YX7aAeMeta } from "/app/app/views/inspired?macro=true";
import { default as _91slug_93BCQ0uZ2tz0Meta } from "/app/app/views/inspired/style/[slug]?macro=true";
import { default as galleryaVeGX1a8iEMeta } from "/app/app/views/gallery?macro=true";
import { default as _91slug_93Es3TTEbUEtMeta } from "/app/app/views/gallery/image/[slug]?macro=true";
import { default as agency7NSf8qsCkjMeta } from "/app/app/views/agency?macro=true";
import { default as _91slug_93wHXklegjjYMeta } from "/app/app/views/agency/[slug]?macro=true";
export default [
  {
    name: defaultMMV5qJuxEqMeta?.name ?? "default",
    path: defaultMMV5qJuxEqMeta?.path ?? "/default",
    meta: defaultMMV5qJuxEqMeta || {},
    alias: defaultMMV5qJuxEqMeta?.alias || [],
    redirect: defaultMMV5qJuxEqMeta?.redirect || undefined,
    component: () => import("/app/pages/default.vue").then(m => m.default || m)
  },
  {
    name: collection2v3lynU6UOMeta?.name ?? "collection",
    path: collection2v3lynU6UOMeta?.path ?? "/bo-suu-tap/",
    alias: ["/en/collection"].concat(collection2v3lynU6UOMeta?.alias || []),
    meta: collection2v3lynU6UOMeta || {},
    redirect: collection2v3lynU6UOMeta?.redirect || undefined,
    component: () => import("/app/app/views/collection").then(m => m.default || m)
  },
  {
    name: _91slug_93fYrUodh70gMeta?.name ?? "collection_detail",
    path: _91slug_93fYrUodh70gMeta?.path ?? "/bo-suu-tap/:slug",
    alias: ["/en/collection/:slug"].concat(_91slug_93fYrUodh70gMeta?.alias || []),
    meta: _91slug_93fYrUodh70gMeta || {},
    redirect: _91slug_93fYrUodh70gMeta?.redirect || undefined,
    component: () => import("/app/app/views/collection/[slug]").then(m => m.default || m)
  },
  {
    name: homew7y6eWECMEMeta?.name ?? "home",
    path: homew7y6eWECMEMeta?.path ?? "/",
    alias: ["/en/"].concat(homew7y6eWECMEMeta?.alias || []),
    meta: homew7y6eWECMEMeta || {},
    redirect: homew7y6eWECMEMeta?.redirect || undefined,
    component: () => import("/app/app/views/home").then(m => m.default || m)
  },
  {
    name: searcht3XBQZO6UAMeta?.name ?? "search",
    path: searcht3XBQZO6UAMeta?.path ?? "/tim-kiem",
    alias: ["/en/search"].concat(searcht3XBQZO6UAMeta?.alias || []),
    meta: searcht3XBQZO6UAMeta || {},
    redirect: searcht3XBQZO6UAMeta?.redirect || undefined,
    component: () => import("/app/app/views/search").then(m => m.default || m)
  },
  {
    name: aboutkUC1q6gCvzMeta?.name ?? "about",
    path: aboutkUC1q6gCvzMeta?.path ?? "/ve-chung-toi",
    alias: ["/en/about"].concat(aboutkUC1q6gCvzMeta?.alias || []),
    meta: aboutkUC1q6gCvzMeta || {},
    redirect: aboutkUC1q6gCvzMeta?.redirect || undefined,
    component: () => import("/app/app/views/about").then(m => m.default || m)
  },
  {
    name: introductionsyivvGFcn1Meta?.name ?? "introduction",
    path: introductionsyivvGFcn1Meta?.path ?? "/gioi-thieu",
    alias: ["/en/introduction"].concat(introductionsyivvGFcn1Meta?.alias || []),
    meta: introductionsyivvGFcn1Meta || {},
    redirect: introductionsyivvGFcn1Meta?.redirect || undefined,
    component: () => import("/app/app/views/introduction").then(m => m.default || m)
  },
  {
    name: contact9xppTWObgXMeta?.name ?? "contact",
    path: contact9xppTWObgXMeta?.path ?? "/lien-he",
    alias: ["/en/contact"].concat(contact9xppTWObgXMeta?.alias || []),
    meta: contact9xppTWObgXMeta || {},
    redirect: contact9xppTWObgXMeta?.redirect || undefined,
    component: () => import("/app/app/views/contact").then(m => m.default || m)
  },
  {
    name: newsfQSK3wWEBvMeta?.name ?? "news",
    path: newsfQSK3wWEBvMeta?.path ?? "/tin-tuc",
    alias: ["/en/news"].concat(newsfQSK3wWEBvMeta?.alias || []),
    meta: newsfQSK3wWEBvMeta || {},
    redirect: newsfQSK3wWEBvMeta?.redirect || undefined,
    component: () => import("/app/app/views/news").then(m => m.default || m)
  },
  {
    name: _91slug_93PELkVboBtMMeta?.name ?? "news_detail",
    path: _91slug_93PELkVboBtMMeta?.path ?? "/tin-tuc/:slug",
    alias: ["/en/news/:slug"].concat(_91slug_93PELkVboBtMMeta?.alias || []),
    meta: _91slug_93PELkVboBtMMeta || {},
    redirect: _91slug_93PELkVboBtMMeta?.redirect || undefined,
    component: () => import("/app/app/views/news/[slug]").then(m => m.default || m)
  },
  {
    name: product1ETEH5FmI1Meta?.name ?? "product",
    path: product1ETEH5FmI1Meta?.path ?? "/san-pham",
    alias: ["/en/product"].concat(product1ETEH5FmI1Meta?.alias || []),
    meta: product1ETEH5FmI1Meta || {},
    redirect: product1ETEH5FmI1Meta?.redirect || undefined,
    component: () => import("/app/app/views/product").then(m => m.default || m)
  },
  {
    name: _91slug_93PT6EbgbpsnMeta?.name ?? "product_detail",
    path: _91slug_93PT6EbgbpsnMeta?.path ?? "/san-pham/:slug",
    alias: ["/en/product/:slug"].concat(_91slug_93PT6EbgbpsnMeta?.alias || []),
    meta: _91slug_93PT6EbgbpsnMeta || {},
    redirect: _91slug_93PT6EbgbpsnMeta?.redirect || undefined,
    component: () => import("/app/app/views/product/[slug]").then(m => m.default || m)
  },
  {
    name: _91slug_93zKaOgT6NKAMeta?.name ?? "category_detail",
    path: _91slug_93zKaOgT6NKAMeta?.path ?? "/loai-san-pham/:slug",
    alias: ["/en/category/:slug"].concat(_91slug_93zKaOgT6NKAMeta?.alias || []),
    meta: _91slug_93zKaOgT6NKAMeta || {},
    redirect: _91slug_93zKaOgT6NKAMeta?.redirect || undefined,
    component: () => import("/app/app/views/category/[slug]").then(m => m.default || m)
  },
  {
    name: shoppingCarthdS3tBOsodMeta?.name ?? "shopping_cart",
    path: shoppingCarthdS3tBOsodMeta?.path ?? "/lien-he-mua-hang",
    alias: ["/en/cart"].concat(shoppingCarthdS3tBOsodMeta?.alias || []),
    meta: shoppingCarthdS3tBOsodMeta || {},
    redirect: shoppingCarthdS3tBOsodMeta?.redirect || undefined,
    component: () => import("/app/app/views/shoppingCart").then(m => m.default || m)
  },
  {
    name: placeToBuyCfuUBr4FlqMeta?.name ?? "place_to_buy",
    path: placeToBuyCfuUBr4FlqMeta?.path ?? "/tim-noi-mua",
    alias: ["/en/placeToBuy"].concat(placeToBuyCfuUBr4FlqMeta?.alias || []),
    meta: placeToBuyCfuUBr4FlqMeta || {},
    redirect: placeToBuyCfuUBr4FlqMeta?.redirect || undefined,
    component: () => import("/app/app/views/placeToBuy").then(m => m.default || m)
  },
  {
    name: guaranteew5cBcpD4bIMeta?.name ?? "guarantee",
    path: guaranteew5cBcpD4bIMeta?.path ?? "/bao-hanh",
    alias: ["/en/guarantee"].concat(guaranteew5cBcpD4bIMeta?.alias || []),
    meta: guaranteew5cBcpD4bIMeta || {},
    redirect: guaranteew5cBcpD4bIMeta?.redirect || undefined,
    component: () => import("/app/app/views/guarantee").then(m => m.default || m)
  },
  {
    name: installationSupportB00PeT6vpDMeta?.name ?? "installation_support",
    path: installationSupportB00PeT6vpDMeta?.path ?? "/ho-tro-lap-dat",
    alias: ["/en/installationSupport"].concat(installationSupportB00PeT6vpDMeta?.alias || []),
    meta: installationSupportB00PeT6vpDMeta || {},
    redirect: installationSupportB00PeT6vpDMeta?.redirect || undefined,
    component: () => import("/app/app/views/installationSupport").then(m => m.default || m)
  },
  {
    name: inspiredscZ9YX7aAeMeta?.name ?? "inspired",
    path: inspiredscZ9YX7aAeMeta?.path ?? "/truyen-cam-hung",
    alias: ["/en/inspired"].concat(inspiredscZ9YX7aAeMeta?.alias || []),
    meta: inspiredscZ9YX7aAeMeta || {},
    redirect: inspiredscZ9YX7aAeMeta?.redirect || undefined,
    component: () => import("/app/app/views/inspired").then(m => m.default || m)
  },
  {
    name: _91slug_93BCQ0uZ2tz0Meta?.name ?? "style_detail",
    path: _91slug_93BCQ0uZ2tz0Meta?.path ?? "/phong-cach/:slug",
    alias: ["/en/style/:slug"].concat(_91slug_93BCQ0uZ2tz0Meta?.alias || []),
    meta: _91slug_93BCQ0uZ2tz0Meta || {},
    redirect: _91slug_93BCQ0uZ2tz0Meta?.redirect || undefined,
    component: () => import("/app/app/views/inspired/style/[slug]").then(m => m.default || m)
  },
  {
    name: galleryaVeGX1a8iEMeta?.name ?? "gallery_index",
    path: galleryaVeGX1a8iEMeta?.path ?? "/thu-vien",
    alias: ["/en/gallery"].concat(galleryaVeGX1a8iEMeta?.alias || []),
    meta: galleryaVeGX1a8iEMeta || {},
    redirect: galleryaVeGX1a8iEMeta?.redirect || undefined,
    component: () => import("/app/app/views/gallery").then(m => m.default || m)
  },
  {
    name: _91slug_93Es3TTEbUEtMeta?.name ?? "gallery_image",
    path: _91slug_93Es3TTEbUEtMeta?.path ?? "/thu-vien/:slug",
    alias: ["/en/gallery/:slug"].concat(_91slug_93Es3TTEbUEtMeta?.alias || []),
    meta: _91slug_93Es3TTEbUEtMeta || {},
    redirect: _91slug_93Es3TTEbUEtMeta?.redirect || undefined,
    component: () => import("/app/app/views/gallery/image/[slug]").then(m => m.default || m)
  },
  {
    name: agency7NSf8qsCkjMeta?.name ?? "agency",
    path: agency7NSf8qsCkjMeta?.path ?? "/dai-ly",
    alias: ["/en/agency"].concat(agency7NSf8qsCkjMeta?.alias || []),
    meta: agency7NSf8qsCkjMeta || {},
    redirect: agency7NSf8qsCkjMeta?.redirect || undefined,
    component: () => import("/app/app/views/agency").then(m => m.default || m)
  },
  {
    name: _91slug_93wHXklegjjYMeta?.name ?? "agency_detail",
    path: _91slug_93wHXklegjjYMeta?.path ?? "/dai-ly/:slug",
    alias: ["/en/agency/:slug"].concat(_91slug_93wHXklegjjYMeta?.alias || []),
    meta: _91slug_93wHXklegjjYMeta || {},
    redirect: _91slug_93wHXklegjjYMeta?.redirect || undefined,
    component: () => import("/app/app/views/agency/[slug]").then(m => m.default || m)
  }
]